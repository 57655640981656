import { Route } from '@angular/router';
import { AuthGuard } from 'app/services/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'sign-in' },
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'pagares' },

    // Auth routes for guests
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.module').then(
                        (m) => m.AuthSignInModule
                    ),
            },
            {
                path: 'change-password',
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import(
                        './modules/auth/change-password/change-password.module'
                    ).then((m) => m.ChangePasswordModule),
            },
        ],
    },

    // Private routes
    {
        path: 'home',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'classic',
        },
        children: [
            
            {
                path: 'pagares',
                loadChildren: () =>
                    import('./modules/home/pagares/pagares.module').then(
                        (m) => m.PagaresModule
                    ),
            },
            {
                path: 'judicial',
                loadChildren: () =>
                    import('./modules/home/judicial/judicial.module').then(
                        (m) => m.JudicialModule
                    ),
            },
            {
                path: 'reportes',
                loadChildren: () =>
                    import('./modules/home/reportes/reportes.module').then(
                        (m) => m.ReportesModule
                    ),
            },
            {
                path: 'consulta',
                loadChildren: () =>
                    import('./modules/home/consulta/consulta.module').then(
                        (m) => m.ConsultaModule
                    ),
            },
            {
                path: 'admin',
                loadChildren: () =>
                    import('./modules/home/admin/admin.module').then((m) => m.AdminModule),
            },
            {
                path: 'auditoria',
                loadChildren: () =>
                    import('./modules/home/auditoria/auditoria.module').then(
                        (m) => m.AuditoriaModule
                    ),
            },
            {
                path: 'sistema',
                loadChildren: () =>
                    import('./modules/home/sistema/sistema.module').then(
                        (m) => m.SistemaModule
                    ),
            },
            {
                path: 'sistema/correos',
                loadChildren: () =>
                    import('./modules/home/sistema/correos/correos.module').then(
                        (m) => m.CorreosModule
                    ),
            },
            {
                path: 'sistema/formulas',
                loadChildren: () =>
                    import('./modules/home/sistema/formulas/formulas.module').then(
                        (m) => m.FormulasModule
                    ),
            },
            {
                path: 'sistema/plantillas',
                loadChildren: () =>
                    import('./modules/home/sistema/plantillas/plantillas.module').then(
                        (m) => m.PlantillasModule
                    ),
            },
            {
                path: 'sistema/plantillas/editor-plantilla/:ID',
                loadChildren: () =>
                    import('./modules/home/sistema/plantillas/editor-plantilla/editor-plantilla.module').then(
                        (m) => m.EditorPlantillaModule
                    ),
            },
            {
                path: 'sistema/plantillas/editor-plantilla',
                loadChildren: () =>
                    import('./modules/home/sistema/plantillas/editor-plantilla/editor-plantilla.module').then(
                        (m) => m.EditorPlantillaModule
                    ),
            },
            {
                path: 'sistema/folios',
                loadChildren: () =>
                    import('./modules/home/sistema/folios/folios.module').then(
                        (m) => m.FoliosModule
                    ),
            },
            {
                path: 'sistema/folios/detalle/:ID',
                loadChildren: () =>
                    import('./modules/home/sistema/folios/detalle/detalle.module').then(
                        (m) => m.DetalleFolioModule
                    ),
            },
            {
                path: 'judicial/detalle/:ID_CARPETA',
                loadChildren: () =>
                    import('./modules/home/judicial/detalle/detalle.module').then(
                        (m) => m.DetalleModule
                    ),
            },
            {
                path: 'reportes/formulario',
                loadChildren: () =>
                    import('./modules/home/reportes/formulario/formulario.module').then(
                        (m) => m.FormularioModule
                    ),
            },
            {
                path: 'reportes/libro',
                loadChildren: () =>
                    import('./modules/home/reportes/libro/libro.module').then(
                        (m) => m.LibroModule
                    ),
            },
            {
                path: 'reportes/reporte-pagares',
                loadChildren: () =>
                    import(
                        './modules/home/reportes/reporte-pagares/reporte-pagares.module'
                    ).then((m) => m.ReportePagaresModule),
            },
            {
                path: 'reportes/reporte-folios',
                loadChildren: () =>
                    import(
                        './modules/home/reportes/reporte-folios/reporte-folios.module'
                    ).then((m) => m.ReporteFoliosModule),
            },
            {
                path: 'admin/roles',
                loadChildren: () =>
                    import(
                        './modules/home/admin/roles/roles.module'
                    ).then((m) => m.RolesModule),
            },
            {
                path: 'admin/usuarios',
                loadChildren: () =>
                    import(
                        './modules/home/admin/usuarios/usuarios.module'
                    ).then((m) => m.UsuariosModule),
            },
        ]
    },
    {
        path: 'pdf',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: ':ID_PROCESO',
                loadChildren: () =>
                    import('./modules/pdf/group-pagare/group-pagare.module').then(
                        (m) => m.GroupPagareModule
                    ),
            },
           
        ]
        
    },
   

    
];
