
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RutValidationDirective } from './rut-validation.directive';
import { OnlynumberDirective } from './onlynumber.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    RutValidationDirective,
    OnlynumberDirective
  ],
  exports: [
    RutValidationDirective,
    OnlynumberDirective
  ]
})
export class DirectivesModule { }