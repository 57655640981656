<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="bg-primary-white-02 print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'" [navigation]="navigation.default" [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center h-20 p-6 pb-0">
            <img class="w-22" src="../../../../../assets/imgs/logo.svg" alt="Logo">
        </div>
        <!-- welcome -->
        <div class="flex gap-3 mt-10 mb-5">
            <div class="px-5">
                <p class="text-xl font-bold text-primary-black-01">
                    {{user?.NOMBRE}}
                </p>
                <p class="text-primary-gray-01">
                    Último acceso: {{user?.LAST_LOGIN | date:"dd/MM/yyyy HH:mm"}}
                </p>
            </div>
        </div>
    </ng-container>


    <ng-container fuseVerticalNavigationFooter>
        <div class="flex items-center justify-center px-3 mt-2 mb-4 flex-0 opacity-6">
            <div (click)="logout()"
                class="gap-4 left-0 py-2 pl-4 w-full rounded-md text-left hover:bg-primary-pink-01 hover:text-primary transition-colors duration-300 ease-in-out flex items-center">
                <mat-icon class="w-[15px] min-w-[15px] text-current" svgIcon="icons:logout"></mat-icon>
                <p class="">Cerrar sesión</p>
            </div>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex items-center w-full h-16 px-4 shadow flex-0 md:px-6 z-49 dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
        *ngIf="false">
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon class="text-primary" [svgIcon]="'icons:nav-icon'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2" *ngIf="false">
            <!-- <languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <search [appearance]="'bar'"></search>
            <shortcuts></shortcuts>
            <messages></messages> -->
            <notifications></notifications>
            <!-- <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button>
            <user></user> -->
        </div>
    </div>

    <div class="fixed flex items-center justify-center z-90">
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon class="text-primary" [svgIcon]="'icons:nav-icon'"></mat-icon>
        </button>
    </div>
    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div
        class="relative flex items-center justify-start w-full h-10 px-4 border-t flex-0 md:px-6 z-49 bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Pagaredigital.cl &copy; {{currentYear}}</span>
    </div>

</div>