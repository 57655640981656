import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { TokenStorageService } from "./token-storage.service";
import { Route, UrlSegment, Router } from '@angular/router';
import { UserService } from './user.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private tokenService: TokenStorageService,
    private router: Router,
    private user: UserService,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | Observable<boolean> {
    // mockup login---Eliminar
    // return true;
    //---------

    return this.isUserReady()
  }

  isUserReady() {
    return this.user.user$.pipe(
      switchMap(
        (user) => {
          if (user) {
            return of(true);
          }

          this.router.navigate(['sign-in']);
          return of(false);
        }
      )
    )
  }

}
