import { Component } from '@angular/core';
import { UserService } from './services/user.service';
import { TokenStorageService } from './services/token-storage.service';
import { take } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    // windowScrolled = false;

    /**
     * Constructor
     */
    constructor(private _user: UserService, private _tokenService: TokenStorageService, private viewPortScroller: ViewportScroller) {
        let logintoken = this._tokenService.getLoginToken();
        if (logintoken) {
            this._user.logIn(logintoken).pipe(take(1)).subscribe();
        } else {
            this._user.user = null;
        }
    }

    // ngOnInit() {
    //     window.addEventListener('scroll', () => {
    //         this.windowScrolled = window.scrollY !== 0;
    //     });
    // }

    get windowScrolled(): boolean {
        let [x, y] = this.viewPortScroller.getScrollPosition();
        return y !== 0;
    }

    scrollToTop(): void {
        // window.scrollTo(0, 0);
        // window.scroll({
        //     top: 0,
        //     left: 0,
        //     behavior: 'smooth',
        // });

        this.viewPortScroller.scrollToPosition([0, 0]);
    }
}
