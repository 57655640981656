import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { TokenStorageService } from '../services/token-storage.service';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
    constructor(private injector: Injector) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let tokenservice = this.injector.get(TokenStorageService);
        let tokenizedReq = req.clone({
            setHeaders: {
                Authorization: `Bearer ${tokenservice.getLoginToken()}`,
                'x-token': `Bearer ${tokenservice.getToken()}`,
                'x-gw': environment.tokenApi,
            },
        });
        return next.handle(tokenizedReq);
    }
}
