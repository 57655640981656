import { Injectable } from '@angular/core';

// Token
const TOKEN_KEY = 'accessToken';
const LOGIN_TOKEN_KEY = 'loginToken';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() { }

  // clear Token

  clearTokens() {
    window.localStorage.clear();
  }
  clearToken() {
    window.localStorage.removeItem(TOKEN_KEY);
  }

  public saveToken(token: string) {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  clearLoginToken() {
    window.localStorage.removeItem(LOGIN_TOKEN_KEY);
  }

  public saveLoginToken(token: string) {
    window.localStorage.removeItem(LOGIN_TOKEN_KEY);
    window.localStorage.setItem(LOGIN_TOKEN_KEY, token);
  }

  public getLoginToken(): string {
    return localStorage.getItem(LOGIN_TOKEN_KEY);
  }
}
