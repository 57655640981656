import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { TokenStorageService } from "./token-storage.service";
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { User, UserPermissions, changePassword } from 'app/models/user.model';
import { environment } from 'environments/environment';
import { Menu } from 'app/models/menu.model';
import { Navigation } from 'app/core/navigation/navigation.types';
import { FuseNavigationItem } from '@fuse/components/navigation';
@Injectable({
    providedIn: 'root'
})
export class UserService {
    readonly APIurl = environment.apis;

    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    private _menu: ReplaySubject<Menu[]> = new ReplaySubject<Menu[]>(1);
    /**
     * Constructor
     */
    constructor(
        private http: HttpClient,
        private tokenService: TokenStorageService,
        private router: Router,
        @Inject(DOCUMENT) private doc: Document
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    /**
     * Setter & getter for menu
     *
     * @param value
     */
    set menu(value: Menu[]) {
        // Store the value
        this._menu.next(value);
    }

    get menu$(): Observable<Menu[]> {
        return this._menu.asObservable();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
    * Set the current logged in user data by email
    */
    setCurrentUser(): Observable<boolean> {
        return this.getUserInfo()
            .pipe(
                switchMap((response) => {
                    if (response) {
                        // Store the user on the user service
                        this.user = response.user;

                        let currentMenu = this.setMenu(response.permissions);
                        this.menu = currentMenu;

                        if (response.changePassword) {
                            this.router.navigateByUrl('change-password');
                        } else {
                            let exist = currentMenu.some(f => f.link == this.doc.location.pathname);
                            if (!exist) {
                                this.router.navigateByUrl(currentMenu[0].link);
                            }
                        }
                        return of(true);
                    } else {
                        this.tokenService.clearToken();
                        return of(false);
                    }
                }),
                catchError((err, caught) => {
                    // console.log(err);
                    this.tokenService.clearTokens();
                    this.router.navigate(['sign-in']);
                    return of(false);
                    // return caught;
                })
            );
    }

    cleanUser() {
        this.user = null;
        this.tokenService.clearTokens();
    }

    logIn(token: string): Observable<boolean> {
        this.tokenService.clearTokens();
        this.tokenService.saveLoginToken(token);
        return this.setCurrentUser();
    }

    logOut() {
        this.user = null;
        this.tokenService.clearTokens();
        this.router.navigate(['sign-in']);
    }

    private setMenu(permissions: string[]): Menu[] {
        let example = [
            "ADMINISTACION ROLES Y USUARIOS",
            "CARPETA DIGITAL JUDICIAL",
            "CONSULTA EXTERNOS",
            "GENERACIÓN DE PAGARÉS",
            "LOGS Y AUDITORIA",
            "PARÁMETROS SISTEMA",
            "REPORTES",
        ]

        let arrPermissionMenu: Menu[] = [
            {
                permission: 'GENERACIÓN DE PAGARÉS',
                link: '/home/pagares',
                url: 'pagares',
                icon: 'icons:pagares-icon',
                titulo: 'Generación de pagarés',
                classIcon: 'w-[13.75px] h-[17.5px] min-w-[13.75px] min-h-[17.5px]',
            },
            {
                permission: 'CARPETA DIGITAL JUDICIAL',
                link: '/home/judicial',
                url: 'judicial',
                icon: 'icons:judicial-icon',
                titulo: 'Carpeta digital judicial',
                classIcon: 'w-[15px] h-[15px] min-w-[15px] min-h-[15px]',
            },
            {
                permission: 'REPORTES',
                link: '/home/reportes',
                url: 'reportes',
                icon: 'icons:reportes-icon',
                titulo: 'Reportes',
                classIcon: 'w-[15px] h-[15px] min-w-[15px] min-h-[15px]',
            },
            {
                permission: 'CONSULTA EXTERNOS',
                link: '/home/consulta',
                url: 'consulta',
                icon: 'icons:externos-icon',
                titulo: 'Consulta externos',
                classIcon: 'w-[16.25px] h-[16.25px] min-w-[16.25px] min-h-[16.25px]',
            },
            {
                permission: 'ADMINISTACION ROLES Y USUARIOS',
                link: '/home/admin',
                url: 'admin',
                icon: 'icons:usuario-icon',
                titulo: 'Administración roles y usuarios',
                classIcon: 'w-[15px] h-[16px] min-w-[15px] min-h-[16px]',
            },
            {
                permission: 'LOGS Y AUDITORIA',
                link: '/home/auditoria',
                url: 'auditoria',
                icon: 'icons:files-icon',
                titulo: 'Logs y Auditoría',
                classIcon: 'w-[18px] h-[13px] min-w-[18px] min-h-[13px]',
            },
            {
                permission: 'PARÁMETROS SISTEMA',
                link: '/home/sistema',
                url: 'sistema',
                icon: 'icons:usuario-icon',
                titulo: 'Parámetros Sistema',
                classIcon: 'w-[15px] h-[16px] min-w-[15px] min-h-[16px]',
            }
        ];
        // console.log(permissions)
        return arrPermissionMenu.filter(f => permissions.some(s => s == f.permission));
    }

    transformMenu(expectedMenus: Menu[]): Navigation {

        let navigationItem: FuseNavigationItem[] = expectedMenus.map(m => {
            return {
                id: m.url,
                title: m.titulo,
                type: 'basic',
                icon: m.icon,
                link: m.link,
                classes: {
                    title: null,
                    subtitle: null,
                    icon: m.classIcon,
                    wrapper: null,
                }
                // children: []
            }
        })

        return {
            compact: navigationItem,
            default: navigationItem,
            futuristic: navigationItem,
            horizontal: navigationItem,
        }
    }

    private getUserInfo() {
        const headers = new HttpHeaders({
            'content-type': 'application/json; charset=utf-8'
        });
        let options = { headers: headers };

        return this.http.get<UserPermissions>(this.APIurl + "/user", options);
    }

    changePassword(newPassword: string) {
        const headers = new HttpHeaders({
            'content-type': 'application/json; charset=utf-8'
        });
        let options = { headers: headers };

        const payload = { password: newPassword };

        return this.http.post<changePassword>(this.APIurl + "/user/password", payload, options);
    }
}
