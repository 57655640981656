import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from 'app/services/user.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    /**
     * Constructor
     */
    constructor(private _user: UserService, private router: Router) {}

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request object
        let newReq = req.clone();

        return next.handle(newReq).pipe(
            catchError((error) => {
                // Catch "401 Unauthorized" responses
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    let considerLogout = false;
                    if (this.router.url != '/') {
                        const currentRouteConfig = this.router.config.find(
                            (f) =>
                                f.path === this.router.url.substring(1) ||
                                f.children?.some((s) =>
                                    s.path != '' ? this.router.url.includes(s.path.split(':')[0]) : this.router.url.includes(f.path)
                                )
                        );
                        if (currentRouteConfig != null && currentRouteConfig.canActivate != null) {
                            considerLogout = true;
                        }
                    }
                    if (considerLogout) {
                        this._user.logOut();
                    }
                }

                return throwError(() => error);
            })
        );
    }
}
