import { Directive, Input, Output, ElementRef, HostListener, EventEmitter } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, NgModel, ValidationErrors, FormControl, NgControl } from '@angular/forms'

import { format, validate, clean } from '@dgitals/rut'
// import { element } from 'protractor';

@Directive({
  selector: '[appRutValidation]',
  providers: [{ provide: NG_VALIDATORS, useExisting: RutValidationDirective, multi: true }]
})
export class RutValidationDirective implements Validator {

  constructor(
    private el: ElementRef,
    // private control: NgControl
  ) { }

  //   @Input('ngModel') private initialValue: any;
  //   @Output() ngModelChange = new EventEmitter();

  //  So, you can add HotListener ove input, over focus,over blur...


  @HostListener('blur')
  onBlur() {
    let value = this.el.nativeElement.value;
  
    if (value) {
      let formatedRUT = format(value);
      if (formatedRUT != '-') {
        this.el.nativeElement.value = formatedRUT;
        // abstractControl.setValue(formatedRUT, { emitEvent: false }
        // this.ngModelChange.emit(formatedRUT);
      } else {
        this.el.nativeElement.value = null;
        // abstractControl.setValue(null, { emitEvent: false })
      }
    } else {
      this.el.nativeElement.value = null;
      // abstractControl.setValue(null, { emitEvent: false })
    }
    // const abstractControl = this.control.control;
  }
  
  validate(c: FormControl): ValidationErrors | null {
    // Here we call our static validator function 
    return RutValidationDirective.isValidRut(c);
  }
 

  static isValidRut(control: FormControl): ValidationErrors {
    let validateRUT = true;
    if (control.value) {
      let formatedRUT = format(control.value);
      validateRUT = validate(formatedRUT);
    }
    return validateRUT ? null : { notRut: true };
  }
}

